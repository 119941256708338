/**
 * Helper module for week view
 */
var modules = modules || {};
modules.weekViewManager = function () {
    var helpers = {

        getSelectedDays: function (day_of_week, custom_days) {
            // Adjust mon -> sun => sun -> sat
            var rotated_day_of_week = [].concat(day_of_week);
            rotated_day_of_week.unshift(rotated_day_of_week.pop());
            var days = custom_days ? custom_days : ["S", "M", "T", "W", "TH", "F", "SA"];
            return _.map(_.reject(
                _.map(rotated_day_of_week, function (k, i) {
                    return k ? i : undefined;
                }), _.isUndefined), function (i) {
                return days[i];
            });
        },

        /**
         * Calculate display string from hour number (14), fraction (2 - out of 4) +/- offset_fractions (12)
         * @param hour
         * @param fraction
         * @param offset_fractions
         * @returns {string}
         */
        getDisplayInterval: function (hour, fraction, offset_fractions) {
            var postfix = '';
            var mins = '';
            if (offset_fractions) {
                var new_fractions = hour * 4 + (fraction || 0) + offset_fractions;
                hour = Math.floor(new_fractions / 4) % 24;
                fraction = new_fractions % 4;
            }
            if (fraction) {
                mins = ('0' + 60 * fraction/4).slice(-2)
            } else {
                mins = ''
            }

            if (Number(hour) == 0) {
                hour = '12';
                postfix = ' AM'
            }  else if (hour < 12) {
                postfix = ' AM';
            } else if (hour == 12) {
                postfix = ' PM';
            } else if (hour > 12) {
                hour = hour % 12;
                postfix = ' PM';
            }

            var time = mins == '' ? hour + ':00' + postfix : "" + hour + ':' + mins  + postfix;
            return time;
        },

        getMinutesDisplay: function(mins) {
            return Pmp.Utils.timeWithLocale(this.getDisplayInterval(Math.floor(mins / 60), ((mins % 60) / 15)));
        },

        getHourFraction: function (x) {
            var is_military = x.indexOf('PM') < 0 && x.indexOf('AM') < 0,
                is_pm = x.indexOf('PM') > 0,
                x = x.replace('AM', '').replace('PM', ''),
                parts = x.split(':'),
                hour = Number(parts[0]),
                minutes = parts[1]
                seconds = parts[2] || 0;
            return {
                hour: is_military ? hour : ((is_pm ? 12 : 0) + (hour == 12 ? 0 : hour)),
                fraction: Math.floor((minutes / 60 + seconds / 3600) * 4)
            }
        }

    };

    return {
        getDisplayInterval: helpers.getDisplayInterval,
        getMinutesDisplay: helpers.getMinutesDisplay,
        getHourFraction: helpers.getHourFraction,
        getSelectedDays: helpers.getSelectedDays
    };
}();
