/**
 */
var modules = modules || {}
modules.formElements = (function () {
  var helpers = {
    canuse: function (e) {
      if ($(e).hasClass('disabled')) {
        $(e).blur()
        return false
      }
    },

    addsave: function () {
      $(this).closest('.form-element').find('.controls').show()
    },

    onCancelClick: function () {
      var target = $(e.target)
      if (!target.filter('.quicksave').length) {
        return
      }
      if (!target.find('.cancel').length) {
        return
      }
      var $el = $(this).closest('.form-element'),
        input = $el.find('input, textarea')[0]
      $(input).val($.data(input, 'initial'))
      $el.find('.controls').hide()
    },

    onSaveClick: function () {
      var target = $(e.target)
      if (!target.filter('.quicksave').length) {
        return
      }
      if (!target.find('.save').length) {
        return
      }
      var $el = target.closest('.form-element'),
        form = $(e.target).closest('form'),
        validatorType = $el.attr('has-validator'),
        valid = true

      if (validatorType) {
        var validator = new sr.Validator(form.parent())
        valid = validator.validate()
      }

      if (!valid) {
        $(this).closest('.form-element').find('.controls').hide()
        return
      }

      $.ajax({
        url: $(form).prop('action'),
        method: $(form).prop('method'),
        data: $(form).serialize(),
        success: function (response) {
          input = $el.find('input, textarea')[0]
          $.data(input, 'initial', $(input).val())
          $el.find('.controls').hide()
        },
      })
    },

    textArea: React.createClass({
      componentDidUpdate: function () {
        $(this.getDOMNode()).val(this.props.value)
      },

      onClickHandler: function (e) {
        var target = $(e.target)
        helpers.canuse(this)
        if (_.contains(this.props.classes, 'save')) {
          helpers.onSaveClick(target)
        }
        if (_.contains(this.props.classes, 'cancel')) {
          helpers.onCancelClick(target)
        }
      },

      onChangeHandler: function (e) {
        if (this.props.onChangeHandler) {
          this.props.onChangeHandler(e)
        }
      },

      render: function () {
        return (
          <textarea
            id={this.props.id ? this.props.id : 'id_' + this.props.name}
            data-test={this.props.testId || `sr-textarea-${this.props.name}`}
            name={this.props.name}
            required={this.props.required}
            className={this.props.classes}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
            maxlength={this.props.maxlength}
            autoComplete={this.props.noauto ? 'off' : 'on'}
            data-sr-validate={this.props.validator}
            data-sr-validate-inject={this.props.validator_inject}
            onClick={this.onClickHandler}
            onChange={this.onChangeHandler}
            onFocus={helpers.canuse}
            style={this.props.inputCss}
            onMouseDown={helpers.canuse}
            onKeyUp={helpers.addsave}
            defaultValue={this.props.value}
          />
        )
      },
    }),
    checkBox: React.createClass({
      componentDidMount: function () {
        $(this.getDOMNode()).val(this.props.value)
      },

      componentDidUpdate: function () {
        $(this.getDOMNode()).prop('checked', this.props.checked)
        $(this.getDOMNode()).val(this.props.value)
      },
      onClickHandler: function (e) {
        var target = $(e.target)
        helpers.canuse(target)
        if (_.contains(this.props.classes, 'save')) {
          helpers.onSaveClick(target)
        }
        if (_.contains(this.props.classes, 'cancel')) {
          helpers.onCancelClick(target)
        }
        if (this.props.onClickHandler) {
          this.props.onClickHandler(e)
        }
      },
      render: function () {
        return (
          <input
            type={this.props.type}
            id={this.props.id ? this.props.id : 'id_' + this.props.name}
            name={this.props.name}
            defaultChecked={this.props.checked}
            disabled={this.props.disabled}
            style={this.props.inputCss}
            className={this.props.classes}
            attributes={this.props.attributes}
            data-sr-validate={this.props.validator}
            data-sr-validate-inject={this.props.validator_inject}
            onClick={this.onClickHandler}
            data-additional={this.props.dataAdditional}
            onFocus={helpers.canuse}
            onMouseDown={helpers.canuse}
            onKeyUp={helpers.addsave}
          />
        )
      },
    }),
    radioElement: React.createClass({
      componentDidUpdate: function () {
        $(this.getDOMNode()).val(this.props.value)
      },

      onClickHandler: function (e) {
        if (this.props.onClickHandler) {
          this.props.onClickHandler(e)
        }
      },
      render: function () {
        return (
          <input
            {...this.props}
            type={this.props.type}
            name={this.props.name}
            id={this.props.id ? this.props.id : 'id_' + this.props.name}
            data-test={this.props.testId || `sr-${this.props.type}-${this.props.name}`}
            defaultValue={this.props.value ? this.props.value : 1}
            defaultChecked={this.props.checkit == this.props.value}
            defaultChecked={!this.props.checkit && this.props.defaultradio}
            disabled={this.props.disabled}
            data-sr-validate={this.props.validator}
            data-sr-validate-inject={this.props.validator_inject}
            style={this.props.inputCss}
            onClick={this.onClickHandler}
            onFocus={helpers.canuse}
            onMouseDown={helpers.canuse}
            onKeyUp={helpers.addsave}
          />
        )
      },
    }),

    selectElement: React.createClass({
      componentDidUpdate: function () {
        $(this.getDOMNode()).val(this.props.value)
      },

      onClickHandler: function (e) {
        var target = $(e.target)
        if (_.contains(this.props.classes, 'save')) {
          helpers.onSaveClick(target)
        }
        if (_.contains(this.props.classes, 'cancel')) {
          helpers.onCancelClick(target)
        }
        if (this.props.onClickHandler) {
          this.props.onClickHandler(e)
        }
      },
      onChangeHandler: function (e) {
        if (this.props.onChangeHandler) {
          this.props.onChangeHandler(e)
        }
      },
      render: function () {
        var imageUri = this.props.media_url + 'images/',
          imageUrl = 'url(' + imageUri + 'selector.png)',
          style = _.extend(
            {
              backgroundImage: imageUrl,
              backgroundPosition: '63% 50%',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '10px',
            },
            this.props.inputCss
          )

        var options = _.map(this.props.options, (value, index) => {
          var val = index
          if (this.props.matchValue) {
            val = value
          }
          var isDisabled = typeof value === 'object' && value.disabled
          return (
            <option value={val} key={index} disabled={isDisabled}>
              {value}
            </option>
          )
        })
        return (
          <select
            name={this.props.name}
            className={this.props.className}
            data-test={this.props.testId || `sr-select-${this.props.name}`}
            style={style}
            disabled={this.props.disabled}
            defaultValue={this.props.value}
            id={this.props.id ? this.props.id : 'id_' + this.props.name}
            onClick={helpers.canuse}
            onChange={this.onChangeHandler}
            onFocus={helpers.canuse}
            onClick={this.onClickHandler}
            onMouseDown={helpers.canuse}
          >
            {options}
          </select>
        )
      },
    }),

    optgroupElement: React.createClass({
      componentDidUpdate: function () {
        $(this.getDOMNode()).val(this.props.value)
      },

      onClickHandler: function (e) {
        var target = $(e.target)
        if (_.contains(this.props.classes, 'save')) {
          helpers.onSaveClick(target)
        }
        if (_.contains(this.props.classes, 'cancel')) {
          helpers.onCancelClick(target)
        }
        if (this.props.onClickHandler) {
          this.props.onClickHandler(e)
        }
      },

      onChangeHandler: function (e) {
        if (this.props.onChangeHandler) {
          this.props.onChangeHandler(e)
        }
      },

      renderOptions: function (options) {
        var optionElements = []
        const allowRepeats = this.props.allowRepeats
        options.forEach(function (option) {
          optionElements.push(
            <option value={option.id} disabled={!allowRepeats && option.isSelected}>
              {option.name}
            </option>
          )
        })
        return optionElements
      },

      render: function () {
        var imageUri = this.props.media_url + 'images/',
          imageUrl = 'url(' + imageUri + 'selector.png)',
          style = _.extend(
            {
              backgroundImage: imageUrl,
              backgroundPositionX: '63%',
              backgroundPositionY: '50%',
              backgroundPosition: '63% 50%',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '10px',
            },
            this.props.inputCss
          )

        var that = this
        var options = []
        const groupOptions = this.props.options

        return (
          <span>
            <select
              style={style}
              name={this.props.name}
              disabled={this.props.disabled}
              defaultValue={this.props.value}
              value={this.props.value}
              id={this.props.id ? this.props.id : 'id_' + this.props.name}
              data-test={this.props.testId || `sr-select-${this.props.name}`}
              onClick={helpers.canuse}
              onChange={this.onChangeHandler}
              onFocus={helpers.canuse}
              onClick={this.onClickHandler}
              onMouseDown={helpers.canuse}
            >
              {Object.keys(groupOptions).map((groupId, index) => {
                return (
                  <optgroup key={index} label={this.props.groupIdToName[groupId].label}>
                    {this.renderOptions(groupOptions[groupId])}
                  </optgroup>
                )
              })}
            </select>
            {!this.props.allowRepeats ? <input type="hidden" name={this.props.name} value={this.props.value} /> : undefined}
          </span>
        )
      },
    }),

    fakeOptgroupElement: React.createClass({
      /* Fake optgroup element with selectable headers built as a styled select element */

      componentDidUpdate: function () {
        $(this.getDOMNode()).val(this.props.value)
      },

      onClickHandler: function (e) {
        var target = $(e.target)
        if (_.contains(this.props.classes, 'save')) {
          helpers.onSaveClick(target)
        }
        if (_.contains(this.props.classes, 'cancel')) {
          helpers.onCancelClick(target)
        }
        if (this.props.onClickHandler) {
          this.props.onClickHandler(e)
        }
      },

      onChangeHandler: function (e) {
        if (this.props.onChangeHandler) {
          this.props.onChangeHandler(e)
        }
      },

      shouldDisableGroupCategory: function (options) {
        for (var option of options) {
          if (option.isSelected) {
            return true
          }
        }
        return false
      },

      renderOptions: function (groupId, options) {
        var optionElements = []
        const allowRepeats = this.props.allowRepeats
        optionElements.push(
          <option value={groupId} disabled={this.shouldDisableGroupCategory(options)} className="fake-optgroup-header">
            {this.props.groupIdToName[groupId].label}
          </option>
        )
        options.forEach(function (option) {
          optionElements.push(
            <option value={option.id} className="fake-optgroup-option" disabled={!allowRepeats && option.isSelected}>
              &nbsp;&nbsp;{option.name}
            </option>
          )
        })
        return optionElements
      },

      render: function () {
        var imageUri = this.props.media_url + 'images/',
          imageUrl = 'url(' + imageUri + 'selector.png)',
          style = _.extend(
            {
              backgroundImage: imageUrl,
              backgroundPositionX: '63%',
              backgroundPositionY: '50%',
              backgroundPosition: '63% 50%',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '10px',
            },
            this.props.inputCss
          )

        var that = this
        var options = []
        const groupOptions = this.props.options

        return (
          <span>
            <select
              style={style}
              name={this.props.name}
              data-test={this.props.testId || `sr-select-${this.props.name}`}
              disabled={this.props.disabled}
              defaultValue={this.props.value}
              value={this.props.value}
              id={this.props.id ? this.props.id : 'id_' + this.props.name}
              onClick={helpers.canuse}
              onChange={this.onChangeHandler}
              onFocus={helpers.canuse}
              onClick={this.onClickHandler}
              onMouseDown={helpers.canuse}
            >
              <option value="">{this.props.defaultText}</option>
              {Object.keys(groupOptions).map((groupId, index) => {
                return this.renderOptions(groupId, groupOptions[groupId])
              })}
            </select>
            {!this.props.allowRepeats && <input type="hidden" name={this.props.name} value={this.props.value} />}
          </span>
        )
      },
    }),

    defaultElement: React.createClass({
      componentDidUpdate: function () {
        $(this.getDOMNode()).val(this.props.value)
      },

      onClickHandler: function (e) {
        var target = $(e.target)
        helpers.canuse(target)
        if (_.contains(this.props.classes, 'save')) {
          helpers.onSaveClick(target)
        }
        if (_.contains(this.props.classes, 'cancel')) {
          helpers.onCancelClick(target)
        }
      },

      onChangeHandler: function (e) {
        if (this.props.onChangeHandler) {
          this.props.onChangeHandler(e)
        }
      },

      render: function () {
        return (
          <input
            type={this.props.type}
            name={this.props.name}
            min={this.props.min}
            max={this.props.max}
            id={this.props.id ? this.props.id : 'id_' + this.props.name}
            data-test={this.props.testId || `sr-${this.props.type}-${this.props.name}`}
            dataEncryptedName={this.props.name}
            defaultValue={this.props.value}
            className={this.props.classes}
            placeholder={this.props.placeholder}
            required={this.props.required}
            autoComplete={this.props.noauto ? 'off' : ''}
            disabled={this.props.disabled}
            maxlength={this.props.maxlength}
            size={this.props.size}
            width={this.props.width}
            onChange={this.onChangeHandler}
            data-sr-validate={this.props.validator}
            data-sr-validate-inject={this.props.validator_inject}
            onClick={this.onClickHandler}
            onFocus={helpers.canuse}
            onMouseDown={helpers.canuse}
            onKeyUp={helpers.addsave}
            style={this.props.inputCss}
          />
        )
      },
    }),
    formelement: React.createClass({
      render: function () {
        var classNames = _.filter([
            'form-element',
            this.props.type,
            this.props.name,
            this.props.quicksave ? 'quicksave' : undefined,
            this.props.disabled ? 'disabled' : undefined,
            this.props.prelabel ? 'prelabeled' : undefined,
            this.props.parentClass,
            this.props.checked ? 'checked' : undefined,
            this.props.checkit && this.props.checkit == this.props.value ? 'checked' : undefined,
            !this.props.checkit && this.props.defaultradio ? 'checked' : undefined,
            this.props.money ? 'money' : undefined,
            this.props.type === 'optgroup' || this.props.type === 'fakeoptgroup' ? 'select' : undefined,
          ]).join(' '),
          label = this.props.labelText ? this.props.labelText : '',
          required_html = this.props.required ? <span className="required">*</span> : undefined,
          label_pre_1 =
            this.props.type != 'checkbox' && this.props.type != 'radio' && label ? (
              <p className="label pre" style={this.props.preLabelStyle}>
                {label}
                {required_html}
              </p>
            ) : undefined,
          postLabelStyle = this.props.postLabelStyle

        return (
          <div className={classNames} style={this.props.style} hasValidator={this.props.validator_type}>
            <label htmlFor={this.props.id ? this.props.id : 'id_' + this.props.name} style={this.props.labelStyle}>
              {label_pre_1}
              <p
                className="input"
                style={this.props.preInputStyle}
                data-test={
                  this.props.type === 'checkbox'
                    ? this.props.testId || `sr-${this.props.type}-${this.props.name}`
                    : 'sr-form-field-container'
                }
              >
                {(() => {
                  switch (this.props.type) {
                    case 'textarea':
                      return <helpers.textArea {...this.props} />
                    case 'checkbox':
                      return <helpers.checkBox {...this.props} />
                    case 'radio':
                      return <helpers.radioElement {...this.props} />
                    case 'select':
                      return <helpers.selectElement {...this.props} />
                    case 'optgroup':
                      return <helpers.optgroupElement {...this.props} />
                    case 'fakeoptgroup':
                      return <helpers.fakeOptgroupElement {...this.props} />
                    default:
                      return <helpers.defaultElement {...this.props} />
                  }
                })()}
              </p>
              {this.props.type == 'checkbox' ? (
                <p style={postLabelStyle} className="label post">
                  {this.props.label}
                </p>
              ) : undefined}
              {this.props.type == 'radio' || this.props.postLabelText ? (
                <p
                  style={postLabelStyle}
                  className="label post"
                  data-test={(this.props.testId || `sr-${this.props.type}-${this.props.name}`) + `-label-post`}
                >
                  {this.props.postLabelText || this.props.label}
                </p>
              ) : undefined}
              {this.props.incrementor ? <span className="minus">&#8211;</span> : undefined}
              {this.props.incrementor ? <span className="plus">+</span> : undefined}
              {this.props.money ? <span className="sign">{this.props.money}</span> : undefined}
              {this.props.quicksave ? (
                <div className="controls">
                  <span className="save">Save</span>
                  <span className="cancel">Cancel</span>
                </div>
              ) : undefined}
            </label>
            {this.props.validator_message ? (
              <p className="validator" id={this.props.validator_id} data-sr-validate-error={this.props.validator_message}>
                {this.props.validator_message}
              </p>
            ) : undefined}
            {this.props.error ? <p className="error">{this.props.error}</p> : undefined}
            {this.props.hint ? <p className="hint">{this.props.hint}</p> : undefined}
          </div>
        )
      },
    }),
  }

  return {
    genereate: function (element, attrs) {
      React.render(<helpers.formelement {...attrs} />, element)
    },
    create: helpers.formelement,
  }
})()
